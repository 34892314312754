<template>
  <v-container v-if="!loading" id="add-User" fluid tag="section">
    <base-material-card
      color="primary"
      icon="mdi-account"
      :title="page_title()"
      class="px-5 py-3"
    >
      <ManageUserBasic
        @alert="show_alert"
        @saved="user_saved"
        :PartnerSelected="partnerId"
        :user_prop="user"
      ></ManageUserBasic>
      <v-alert
        class="ma-2"
        v-if="alert_msg != null"
        :type="success ? 'success' : 'error'"
      >
        {{ $t(alert_msg) }}
      </v-alert>
    </base-material-card>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ManageUserBasic from "@/views/pages/user/index/form/tabs/Basic";

export default {
  name: "AddBanker",

  components: {
    ManageUserBasic,
  },

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    user_id: null,
    partnerId: null,
    edit: false,
    user: null,
    success: null,
    alert_msg: null,
  }),

  computed: {},

  watch: {},

  async created() {
    this.partnerId = JSON.parse(localStorage.getItem("user")).id;
    this.user_id = this.$route.params.user_id;
    this.loading = true;
    if (this.user_id != null && this.user_id != undefined) {
      await this.apiService
        .getRequest("user/".concat(this.user_id))
        .then((resp) => {
          this.user = JSON.parse(resp);
          this.userType = this.user.Type;
          this.edit = true;
        })
        .catch((error) => {
          this.edit = false;
          this.loading = false;
        });
    }
    this.loading = false;
  },

  methods: {
    reset_alert: function () {
      this.success = null;
      this.alert_msg = null;
    },
    show_alert: function (success, msg) {
      this.success = success;
      this.alert_msg = msg;
    },
    user_saved(user) {
      this.user = user;
    },
    page_title: function () {
      if (this.edit && !this.loading) {
        return this.gs.show_name(this.user);
      } else if (!this.edit) {
        return this.$t("add_banker");
      } else {
        return "";
      }
    },
  },
};
</script>
